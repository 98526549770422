<template>
  <el-container class="father">
    <!-- 左侧菜单栏 -->
    <el-aside>
      <el-menu
        router
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        :defaultOpeneds="openeds"
        unique-opened
      >
        <el-submenu :index="item.path + ''" v-for="(item, index) in menuList" :key="index">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>{{ item.title }}</span>
          </template>
          <el-menu-item-group v-if="item.children.length > 0">
            <el-menu-item
              :index="k.path"
              v-for="(k, v) in item.children"
              :key="v"
              @click="cc(k)"
            >{{ k.title }}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
      <!-- <div class="mini-box"></div> -->
    </el-aside>

    <!-- 右侧内容区域 -->
    <el-main class="main">
      <router-view />
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'operation',
  components: {},
  data () {
    return {
      menuList: [
        // {
        //   title: '实时监控',
        //   path: '/monitoring',
        //   children: [],
        //   icon: ''
        // },
        // {
        //   title: '事件回溯',
        //   path: '/operationManage',
        //   icon: '',
        //   children: [
        //     //   { title: '视频回放', path: '/playback', children: [] },
        //     //   {
        //     //     title: '轨迹回放',
        //     //     path: '/track',
        //     //     children: []
        //     //   }
        //   ]
        // },
        {
          title: '调度中心',
          path: '',
          icon: '',
          children: [
            {
              title: '订单调度',
              path: '/operation/dispatch/orderScheduling'
            },
            {
              title: '历史调度',
              path: '/operation/dispatch/history'
            }
          ]
        }
      ],
      openeds: [''],
      falg: true
    }
  },
  methods: {
    handleOpen (index, indexPath) {
      // console.log(index, indexPath)
      if (this.$route.path === index) return
      this.falg = true
      // console.log(this.$route.path)
      this.$router.replace(index)
    },
    handleClose (index, indexPath) {
      // console.log(index, indexPath)
      // console.log(this.$route.path)
    },
    cc (e) {
      // if (e.path === '/operation/recall/playback' || e.path === '/operation/recall/track') {
      //     this.falg = false
      // } else {
      //     this.falg = true
      // }
      // console.log(e)
    }
  }
}
</script>
<style lang="less" scoped>
.father {
  height: 100%;
  width: 100%;
  // display: flex;
  margin: 0;
  .mini-box {
    width: 5.1125rem;
    height: 210px;
    background-color: pink;
  }
  .el-aside {
    // flex: 1;
    height: 100%;
    width: 5.1125rem;
    // background-color: skyblue;
    .el-menu {
      width: 100%;
      height: 100%;
    }
  }
  .main {
    // flex: 1;
    height: 100%;
    width: 100%;
    //  background-color: pink;
    padding: 0;
    margin: 0;
  }
}
</style>
